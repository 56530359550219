<template>
  <div class="refundgoods">
    <title-top>退款详情</title-top>
    <div class="row align-center font32 f-white bg-F62341 h176">
      <span class="ml25">等待商家收货并退款</span>
    </div>
    <div class="row align-center bg-white h80">
      <p class="font28 f-1A1A1A ml25">如果商家收到货并验收无误，将操作退款给您。</p>
    </div>
    <div class="line"></div>
    <div class="row align-center bg-white">
      <img class="imgsize44 ml25 mr16" src="~images/zyimg/wuliu.png" alt />
      <div class="font28 f-333333 mt20 mb20">
        <div>
          退货物流：
          <span style="color:#2FB73C;">{{logistics.express}}（{{logistics.tracking_number}}）</span>
        </div>
        <p class="mt20">买家已发货 {{res.updated_at | formatDate}}</p>
      </div>
    </div>
    <div class="line"></div>
    <div class="column bg-white mb20 h286">
      <span class="font28 f-1A1A1A ml25 mt20">退款说明：</span>
      <p class="font24 f-666666 ml25 mr25 mt20">{{res.remark}}</p>
    </div>
    <div class="column bg-white font28 f-1A1A1A mb20">
      <span class="ml25 mt25">退款信息</span>
      <div class="row between align-center bg-white" v-for="(item,i) in res.order.items" :key="i">
        <img class="imgsize176 br4 ml25 mr16 mt25 mb25" :src="item.image_url" alt />
        <div class="flex1 column font24 f-333333 mt25 mb25">
          <p class="mr25 ellipsis">{{item.product_name}}</p>
          <div class="row between f-999999 mt16 align-center">
            <span>{{item.specification_values | spec}}</span>
            <span class="ml25 mr25">×{{item.quantity}}</span>
          </div>
        </div>
        </div>
        <div class="line"></div>
        <div class="font24 f-999999 bg-white">
          <p class="mt25 ml25 mb16">退款原因：{{res.reason}}</p>
          <p class="ml25 mb16">退款金额：￥{{res.order.paid_amount}}</p>
          <p class="ml25 mb16">申请时间：{{res.created_at | formatDate}}</p>
          <p class="ml25 mb16">退款编号：{{res.number}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import { formatDate } from "common/util";
import { refundsDetail } from "network/igou2";
export default {
  name: "RefundGoods",
  data() {
    return {
      id:this.$route.query.id,
      res: {order:{items:[]}},
      logistics:{}
    };
  },
  mounted() {
    this._refundsDetail();
  },
  methods: {
    _refundsDetail() {
      refundsDetail(this.id).then(res => {
        console.log(res);
        this.res = res.data;
        this.logistics = res.data.logistics?res.data.logistics:{}
      });
    }
  },
  filters: {
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    spec(specification_values) {
      let str = "";
      specification_values.forEach(item => {
        str += item.content + ",";
      });
      if (str) {
        str.substring(0, str.length - 1);
      }
      return str;
    }
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.hd-border {
  width: 2.986667rem;
  height: 1.52rem;
  border: 1px solid #999999;
}
</style>
